:root {
  --brandbrand-1: rgba(225, 238, 124, 1);
  --brandbrand-2: rgba(154, 172, 22, 1);
  --brandbrand-3: rgba(183, 203, 26, 1);
  --brandmain: rgba(214, 232, 77, 1);
  --desktop-extrabold-h1-font-family: "Manrope-ExtraBold", Helvetica;
  --desktop-extrabold-h1-font-size: 40px;
  --desktop-extrabold-h1-font-style: normal;
  --desktop-extrabold-h1-font-weight: 800;
  --desktop-extrabold-h1-letter-spacing: 0px;
  --desktop-extrabold-h1-line-height: normal;
  --desktop-extrabold-h2-font-family: "Manrope-ExtraBold", Helvetica;
  --desktop-extrabold-h2-font-size: 28px;
  --desktop-extrabold-h2-font-style: normal;
  --desktop-extrabold-h2-font-weight: 800;
  --desktop-extrabold-h2-letter-spacing: 0px;
  --desktop-extrabold-h2-line-height: normal;
  --desktop-extrabold-h3-font-family: "Manrope-ExtraBold", Helvetica;
  --desktop-extrabold-h3-font-size: 24px;
  --desktop-extrabold-h3-font-style: normal;
  --desktop-extrabold-h3-font-weight: 800;
  --desktop-extrabold-h3-letter-spacing: 0px;
  --desktop-extrabold-h3-line-height: normal;
  --desktop-extrabold-h4-font-family: "Manrope-Bold", Helvetica;
  --desktop-extrabold-h4-font-size: 18px;
  --desktop-extrabold-h4-font-style: normal;
  --desktop-extrabold-h4-font-weight: 700;
  --desktop-extrabold-h4-letter-spacing: 0px;
  --desktop-extrabold-h4-line-height: normal;
  --desktop-extrabold-h5-font-family: "Manrope-ExtraBold", Helvetica;
  --desktop-extrabold-h5-font-size: 16px;
  --desktop-extrabold-h5-font-style: normal;
  --desktop-extrabold-h5-font-weight: 800;
  --desktop-extrabold-h5-letter-spacing: 0px;
  --desktop-extrabold-h5-line-height: normal;
  --desktop-extrabold-h6-font-family: "Manrope-ExtraBold", Helvetica;
  --desktop-extrabold-h6-font-size: 14px;
  --desktop-extrabold-h6-font-style: normal;
  --desktop-extrabold-h6-font-weight: 800;
  --desktop-extrabold-h6-letter-spacing: 0px;
  --desktop-extrabold-h6-line-height: normal;
  --desktop-extrabold-h7-font-family: "Manrope-ExtraBold", Helvetica;
  --desktop-extrabold-h7-font-size: 12px;
  --desktop-extrabold-h7-font-style: normal;
  --desktop-extrabold-h7-font-weight: 800;
  --desktop-extrabold-h7-letter-spacing: 0px;
  --desktop-extrabold-h7-line-height: normal;
  --desktop-medium-h1-font-family: "Manrope-Medium", Helvetica;
  --desktop-medium-h1-font-size: 40px;
  --desktop-medium-h1-font-style: normal;
  --desktop-medium-h1-font-weight: 500;
  --desktop-medium-h1-letter-spacing: 0px;
  --desktop-medium-h1-line-height: normal;
  --desktop-medium-h2-font-family: "Manrope-Medium", Helvetica;
  --desktop-medium-h2-font-size: 28px;
  --desktop-medium-h2-font-style: normal;
  --desktop-medium-h2-font-weight: 500;
  --desktop-medium-h2-letter-spacing: 0px;
  --desktop-medium-h2-line-height: normal;
  --desktop-medium-h3-font-family: "Manrope-Medium", Helvetica;
  --desktop-medium-h3-font-size: 24px;
  --desktop-medium-h3-font-style: normal;
  --desktop-medium-h3-font-weight: 500;
  --desktop-medium-h3-letter-spacing: 0px;
  --desktop-medium-h3-line-height: normal;
  --desktop-medium-h4-font-family: "Manrope-Medium", Helvetica;
  --desktop-medium-h4-font-size: 18px;
  --desktop-medium-h4-font-style: normal;
  --desktop-medium-h4-font-weight: 500;
  --desktop-medium-h4-letter-spacing: 0px;
  --desktop-medium-h4-line-height: normal;
  --desktop-medium-h5-font-family: "Manrope-Medium", Helvetica;
  --desktop-medium-h5-font-size: 16px;
  --desktop-medium-h5-font-style: normal;
  --desktop-medium-h5-font-weight: 500;
  --desktop-medium-h5-letter-spacing: 0px;
  --desktop-medium-h5-line-height: normal;
  --desktop-medium-h6-font-family: "Manrope-Medium", Helvetica;
  --desktop-medium-h6-font-size: 14px;
  --desktop-medium-h6-font-style: normal;
  --desktop-medium-h6-font-weight: 500;
  --desktop-medium-h6-letter-spacing: 0px;
  --desktop-medium-h6-line-height: normal;
  --desktop-medium-h7-font-family: "Manrope-Medium", Helvetica;
  --desktop-medium-h7-font-size: 12px;
  --desktop-medium-h7-font-style: normal;
  --desktop-medium-h7-font-weight: 500;
  --desktop-medium-h7-letter-spacing: 0px;
  --desktop-medium-h7-line-height: normal;
  --errorerror-1: rgba(125, 32, 32, 1);
  --errorerror-2: rgba(65, 16, 16, 1);
  --errorerror-3: rgba(252, 235, 235, 1);
  --errormain: rgba(207, 53, 53, 1);
  --greygrey-1: rgba(142, 142, 147, 1);
  --greygrey-2: rgba(99, 99, 102, 1);
  --greygrey-3: rgba(58, 58, 60, 1);
  --greygrey-4: rgba(44, 44, 46, 1);
  --greygrey-5: rgba(28, 28, 30, 1);
  --greyprimary: rgba(174, 174, 178, 1);
  --mobile-extrabold-h1-font-family: "Manrope-Bold", Helvetica;
  --mobile-extrabold-h1-font-size: 22px;
  --mobile-extrabold-h1-font-style: normal;
  --mobile-extrabold-h1-font-weight: 700;
  --mobile-extrabold-h1-letter-spacing: 0px;
  --mobile-extrabold-h1-line-height: normal;
  --mobile-extrabold-h2-font-family: "Manrope-ExtraBold", Helvetica;
  --mobile-extrabold-h2-font-size: 20px;
  --mobile-extrabold-h2-font-style: normal;
  --mobile-extrabold-h2-font-weight: 800;
  --mobile-extrabold-h2-letter-spacing: 0px;
  --mobile-extrabold-h2-line-height: normal;
  --mobile-extrabold-h3-font-family: "Manrope-Bold", Helvetica;
  --mobile-extrabold-h3-font-size: 18px;
  --mobile-extrabold-h3-font-style: normal;
  --mobile-extrabold-h3-font-weight: 700;
  --mobile-extrabold-h3-letter-spacing: 0px;
  --mobile-extrabold-h3-line-height: normal;
  --mobile-extrabold-h4-font-family: "Manrope-Bold", Helvetica;
  --mobile-extrabold-h4-font-size: 16px;
  --mobile-extrabold-h4-font-style: normal;
  --mobile-extrabold-h4-font-weight: 700;
  --mobile-extrabold-h4-letter-spacing: 0px;
  --mobile-extrabold-h4-line-height: normal;
  --mobile-extrabold-h5-font-family: "Manrope-Bold", Helvetica;
  --mobile-extrabold-h5-font-size: 14px;
  --mobile-extrabold-h5-font-style: normal;
  --mobile-extrabold-h5-font-weight: 700;
  --mobile-extrabold-h5-letter-spacing: 0px;
  --mobile-extrabold-h5-line-height: normal;
  --mobile-extrabold-h6-font-family: "Manrope-Bold", Helvetica;
  --mobile-extrabold-h6-font-size: 12px;
  --mobile-extrabold-h6-font-style: normal;
  --mobile-extrabold-h6-font-weight: 700;
  --mobile-extrabold-h6-letter-spacing: 0px;
  --mobile-extrabold-h6-line-height: normal;
  --mobile-extrabold-h7-font-family: "Manrope-Bold", Helvetica;
  --mobile-extrabold-h7-font-size: 10px;
  --mobile-extrabold-h7-font-style: normal;
  --mobile-extrabold-h7-font-weight: 700;
  --mobile-extrabold-h7-letter-spacing: 0px;
  --mobile-extrabold-h7-line-height: normal;
  --mobile-medium-h1-font-family: "Manrope-Medium", Helvetica;
  --mobile-medium-h1-font-size: 22px;
  --mobile-medium-h1-font-style: normal;
  --mobile-medium-h1-font-weight: 500;
  --mobile-medium-h1-letter-spacing: 0px;
  --mobile-medium-h1-line-height: normal;
  --mobile-medium-h2-font-family: "Manrope-Medium", Helvetica;
  --mobile-medium-h2-font-size: 20px;
  --mobile-medium-h2-font-style: normal;
  --mobile-medium-h2-font-weight: 500;
  --mobile-medium-h2-letter-spacing: 0px;
  --mobile-medium-h2-line-height: normal;
  --mobile-medium-h3-font-family: "Manrope-Medium", Helvetica;
  --mobile-medium-h3-font-size: 18px;
  --mobile-medium-h3-font-style: normal;
  --mobile-medium-h3-font-weight: 500;
  --mobile-medium-h3-letter-spacing: 0px;
  --mobile-medium-h3-line-height: normal;
  --mobile-medium-h4-font-family: "Manrope-Medium", Helvetica;
  --mobile-medium-h4-font-size: 16px;
  --mobile-medium-h4-font-style: normal;
  --mobile-medium-h4-font-weight: 500;
  --mobile-medium-h4-letter-spacing: 0px;
  --mobile-medium-h4-line-height: normal;
  --mobile-medium-h5-font-family: "Manrope-Medium", Helvetica;
  --mobile-medium-h5-font-size: 14px;
  --mobile-medium-h5-font-style: normal;
  --mobile-medium-h5-font-weight: 500;
  --mobile-medium-h5-letter-spacing: 0px;
  --mobile-medium-h5-line-height: normal;
  --mobile-medium-h6-font-family: "Manrope-Medium", Helvetica;
  --mobile-medium-h6-font-size: 12px;
  --mobile-medium-h6-font-style: normal;
  --mobile-medium-h6-font-weight: 500;
  --mobile-medium-h6-letter-spacing: 0px;
  --mobile-medium-h6-line-height: normal;
  --mobile-medium-h7-font-family: "Manrope-Medium", Helvetica;
  --mobile-medium-h7-font-size: 10px;
  --mobile-medium-h7-font-style: normal;
  --mobile-medium-h7-font-weight: 500;
  --mobile-medium-h7-letter-spacing: 0px;
  --mobile-medium-h7-line-height: normal;
  --shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --text-md-medium-font-family: "Inter-Medium", Helvetica;
  --text-md-medium-font-size: 16px;
  --text-md-medium-font-style: normal;
  --text-md-medium-font-weight: 500;
  --text-md-medium-letter-spacing: 0px;
  --text-md-medium-line-height: 24px;
  --text-md-normal-font-family: "Inter-Regular", Helvetica;
  --text-md-normal-font-size: 16px;
  --text-md-normal-font-style: normal;
  --text-md-normal-font-weight: 400;
  --text-md-normal-letter-spacing: 0px;
  --text-md-normal-line-height: 24px;
  --text-sm-medium-font-family: "Inter-Medium", Helvetica;
  --text-sm-medium-font-size: 14px;
  --text-sm-medium-font-style: normal;
  --text-sm-medium-font-weight: 500;
  --text-sm-medium-letter-spacing: 0px;
  --text-sm-medium-line-height: 20px;
  --text-sm-normal-font-family: "Inter-Regular", Helvetica;
  --text-sm-normal-font-size: 14px;
  --text-sm-normal-font-style: normal;
  --text-sm-normal-font-weight: 400;
  --text-sm-normal-letter-spacing: 0px;
  --text-sm-normal-line-height: 20px;
  --text-xl-normal-font-family: "Inter-Regular", Helvetica;
  --text-xl-normal-font-size: 20px;
  --text-xl-normal-font-style: normal;
  --text-xl-normal-font-weight: 400;
  --text-xl-normal-letter-spacing: 0px;
  --text-xl-normal-line-height: 30px;
  --warningmain: rgba(232, 139, 33, 1);
  --warningwarning-1: rgba(140, 84, 20, 1);
  --warningwarning-2: rgba(72, 43, 10, 1);
  --warningwarning-3: rgba(255, 245, 233, 1);
}